import { render, staticRenderFns } from "./AttendanceMenu.vue?vue&type=template&id=79755e68&scoped=true&"
import script from "./AttendanceMenu.vue?vue&type=script&lang=js&"
export * from "./AttendanceMenu.vue?vue&type=script&lang=js&"
import style0 from "./AttendanceMenu.vue?vue&type=style&index=0&id=79755e68&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79755e68",
  null
  
)

export default component.exports