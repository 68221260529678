<template>
  <div class="attendance-menu">
    <div class="main-menu">
      <router-link
        v-if="menuPermission.Attendance.AttendancePerson"
        :to="{ name: 'AttendancePerson' }"
        :class="[
          $route.name == 'AttendancePerson' ? 'child-menu-active' : '',
        ]">考勤记录
      </router-link>
      <router-link
        v-if="menuPermission.Attendance.AttendanceConfig"
        :to="{ name: 'AttendanceConfig' }"
        :class="[
          $route.name == 'AttendanceConfig' ? 'child-menu-active' : '',
        ]">考勤设置
      </router-link>
      <router-link
        v-if="menuPermission.Attendance.WorkTimeList"
        :to="{ name: 'WorkTimeList' }"
        :class="[
          $route.name == 'WorkTimeList' ? 'child-menu-active' : '',
        ]">排班管理
      </router-link>
      <router-link
        v-if="menuPermission.Attendance.SetWorkTime"
        :to="{ name: 'SetWorkTime' }"
        :class="[
          $route.name == 'SetWorkTime' ? 'child-menu-active' : '',
        ]">上班安排
      </router-link>
      <router-link
        v-if="menuPermission.Attendance.QueryDay"
        :to="{ name: 'QueryDay' }"
        :class="[
          $route.name == 'QueryDay' ? 'child-menu-active' : '',
        ]"> 日统计
      </router-link>
      <router-link
        v-if="menuPermission.Attendance.QueryMonth"
        :to="{ name: 'QueryMonth' }"
        :class="[
          $route.name == 'QueryMonth' ? 'child-menu-active' : '',
        ]">月统计
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AttendanceMenu',
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    menuPermission: function () {
      return JSON.parse(localStorage.getItem("menuPermission"));
    },
  },
}
</script>

<style lang="less" scoped>
@link-color: #2c85d8;

.attendance-menu {
  width: 1200px;
  margin: 0 auto;
  a {
    display: inline-block;
    padding: 10px 20px;
  }
}
</style>
