<template>
  <div class="WorkTimeList">
    <AttendanceMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称" style="margin-bottom: 0">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank"></div>

    <!-- 排班列表 -->
    <div class="wrap">
      <el-table :data="workTimeList" style="width: 100%">
        <el-table-column prop="wmName" label="班次名称"></el-table-column>
        <el-table-column
          prop="wmOfficeHours"
          label="上班时间"
        ></el-table-column>
        <el-table-column
          prop="wmClosingTime"
          label="下班时间"
        ></el-table-column>
        <el-table-column prop="remark" label="班次类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 0">白班</span>
            <span v-if="scope.row.type == 1">夜班</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="deleteWorkTimeItem(scope.row)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="wrap-blank"></div>

    <!-- 排班表单 -->
    <div class="wrap">
      <el-form label-width="100px" style="width: 500px">
        <el-form-item label="班次名称">
          <el-input size="small" v-model="workTimeData.wmName"></el-input>
        </el-form-item>
        <el-form-item label="上班时间">
          <el-time-picker
            v-model="workTimeData.wmOfficeHours"
            value-format="HH:mm:ss"
            :picker-options="{
              selectableRange: '00:00:00 - 23:59:59',
            }"
            placeholder="选择上班时间">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="下班时间">
          <el-time-picker
            v-model="workTimeData.wmClosingTime"
            value-format="HH:mm:ss"
            :picker-options="{
              selectableRange: '00:00:00 - 23:59:59',
            }"
            :placeholder="workTimeData.type == 1 ? '选择次日下班时间' : '选择下班时间'">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="班次类型">
          <el-radio-group v-model="workTimeData.type">
            <el-radio :label="0">白班</el-radio>
            <el-radio :label="1">夜班</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="saveWorkTimeData">添加班次</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<style lang="less" scope>
.WorkTimeList {
  .el-form-item {
    margin-bottom: 0;
  }
  .el-input__inner {
    width: 220px;
  }
  .el-input__inner {
    height: 32px;
  }
}
</style>

<script>
import AttendanceMenu from "@/components/AttendanceMenu.vue";

export default {
  name: "workTimeList",
  components: {
    AttendanceMenu,
  },
  data() {
    return {
      currentProject: null,
      projectList: [],

      workTimeList: [],

      workTimeData: {
        projectId: null,
        wmName: null,
        wmOfficeHours: null,
        wmClosingTime: null,
        type: 0,
      },
      pageNum: 1,
      pageSize: 10,
      totals: 0,
    };
  },
  computed: {
    
  },
  mounted() {
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {
    currentProject: function (newVal) {
      console.log("currentProject watch =>", newVal);
      this.getWrokTimeList();
    },
  },
  methods: {
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        proName: "",
        projectSysNo: "",
        proAddress: "",
        pageNum: 1,
        pageSize: 10,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载项目所有排版列表
    getWrokTimeList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: that.currentProject,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgWorkforceManagement/queryList",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("getWrokTimeList =>", res);
          that.workTimeList = res.data;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 删除排班
    deleteWorkTimeItem: function (data) {
      console.log("deleteWorkTimeItem=>", data);
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        wgWorkforceManagements: [{ wmId: data.wmId }],
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgWorkforceManagement/delAll",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.loading.close();
          that.getWrokTimeList();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 保存排班数据
    saveWorkTimeData: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: this.currentProject,
        wmName: this.workTimeData.wmName,
        wmOfficeHours: this.workTimeData.wmOfficeHours,
        wmClosingTime: this.workTimeData.wmClosingTime,
        type: this.workTimeData.type,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgWorkforceManagement/insertWorkforceManagement",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.loading.close();
          that.getWrokTimeList();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    }
  },
};
</script>
